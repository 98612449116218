<template>
  <div class="card-modulo">
    <h5 class="card-modulo-title  text-left">{{ name }}</h5>
    <img
      v-if="active"
      src="@/assets/Images/Formation/Enmascarar grupo 37.png"
    />
    <img
      v-if="!active"
      src="@/assets/Images/Formation/Enmascarar grupo 125.png"
    />
    <h5 class="card-modulo-footer text-right">{{ count }}</h5>
  </div>
</template>

<script>
export default {
  props: ["name", "count", "active"],
};
</script>

<style scoped>
@media (max-width: 575.98px) {
  .card-modulo {
    min-width: 75% !important;
    height: 10rem !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .card-modulo {
    min-width: 65% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .card-modulo {
    min-width: 50% !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .card-modulo {
    min-width: 40% !important;
  }
}

@media (min-width: 1200px) {
}

.card-modulo {
  float: left;
  width: 75.333%;
  height: 305px;
  padding: 1.85rem;
  margin-right: 1.3rem;
  margin-bottom: 2rem;
  border: 0;
  cursor: pointer;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  position: relative;
}

.card-modulo.active {
  border: 1px solid #ff0313;
  color: #ff0313;
}

.card-modulo.active > * {
  color: #ff0313;
}

.card-modulo img {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 219px;
}

.card-modulo-title {
  margin-top: 20px;
  margin-left: 55px;
}

.card-modulo-title,
.card-modulo-footer {
  text-align: left;
  font: normal normal 600 45px/55px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #8d8d8d;
}

.card-modulo-title.active,
.card-modulo-footer.card-modulo.active {
  color: #f9334a;
}

.card-modulo-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1.85rem;
}

.content-card-modulo {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
}
</style>
