<template>
  <div class="container-fluid">
    <div class="row mx-auto my-auto" id="carousel">
      <div class="col-12">
        <!--Carousel Wrapper-->
        <div
          id="multi-item-example"
          class="carousel slide carousel-multi-item"
          data-ride="carousel"
        >
          <!--Indicators-->
          <ol class="carousel-indicators">
            <li
              data-target="#multi-item-example"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#multi-item-example" data-slide-to="1"></li>
          </ol>
          <!--/.Indicators-->

          <!--Slides-->
          <div class="carousel-inner" role="listbox">
            <!--First slide-->
            <div class="carousel-item active">
              <div class="row item">
                <div class="col-12">
                  <FormationCard :info="infoCards[0]" />
                </div>
              </div>
            </div>
            <!--/.First slide-->

            <!--Second slide-->
            <div class="carousel-item">
              <div class="row item">
                <div class="col-12">
                  <FormationCard :info="infoCards[1]" />
                </div>
              </div>
            </div>
            <!--/.Second slide-->
          </div>
          <!--/.Slides-->
        </div>
        <!--/.Carousel Wrapper-->
      </div>
    </div>
  </div>
</template>

<script>
import FormationCard from "@/components/FormationCard.vue";
export default {
  components: {
    FormationCard,
  },
  props: {
    infoCards: Array,
  },
};
</script>

<style scoped>
.item {
  margin: 5px;
}

.container-fluid {
  padding: 0 10px;
}

#carousel > .col-12, .item > .col-12 {
  padding: 0;
}

.carousel-indicators {
  left: 0;
  bottom: 10%;
}

.carousel-indicators li {
  background-color: #737373 !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  opacity: 1 !important;
}

.carousel-indicators .active {
  background-color: #ff0313 !important;
}
</style>
