<template>
  <div id="contact-modal" class="modal modal-centered fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4 px-md-5 py-md-4">
          <h3 class="font-weight-bold text-center">Rejoignez la liste d'attente</h3>
          <div class="text-left">
            <p>
              Nous devons limiter les inscriptions pour pouvoir effectuer un suivi de qualité. Et pour gérer le nombre d'inscriptions, nous avons mis en place une liste d'attente.
            </p>
            <p>
              En vous inscrivant ici nous allons vous informer rapidement si vous pouvez rejoindre la formation. Nous ouvrons la formation dès que des places se libèrent.
            </p>
            <p>
              Si vous n'avez pas reçu d'email de confirmation 2 minutes  après  votre inscription, regardez dans vos messages indesirables.
            </p>
            <form class="row" id="contact_form">
              <div class="col-12 form-group">
                <label for="from_name" class="font-weight-bold">
                  Entrez votre prénom <span class="text-danger">*</span>
                </label>
                <input type="text" class="form-control" name="from_name" id="from_name" placeholder="Votre prénom">
              </div>
              <div class="col-12 form-group">
                <label for="from_email" class="font-weight-bold">
                  Entrez votre email <span class="text-danger">*</span>
                </label>
                <input type="email" class="form-control" name="from_email" id="from_email" placeholder="Votre meilleur email">
              </div>
              <div class="col-12 form-group d-none">
                <input type="text" class="form-control" name="message" id="message" value="Contact me!">
              </div>
              <div class="col-12 form-group d-none">
                <input type="email" class="form-control" name="to_name" id="to_name" value="preparationpf@gmail.com">
              </div>
              <div class="col-12">
                <small>
                  Nous respectons votre vie privée et vos données ne seront jamais partagées avec des tiers. Votre adresse e-mail est uniquement utilisée pour vous envoyer des informations sur la préparation physique en football, ainsi que pour vous partager des offres sur les programmes et formation. Vous pouvez vous désabonner à tout moment grâce au lien inclus en bas de chaque e-mail.
                </small>
              </div>
              <div class="col-12 mt-4 mb-3 text-center">
                <input type="submit" value="Rejoindre la liste d'attente" class="btn btn-primary font-weight-bold px-3">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      window.emailjs.init("user_od4hxjkv6iNt08Hd5vJPS");
      const form = document.getElementById("contact_form")
      form.addEventListener("submit", event => {
        event.preventDefault();
        window.emailjs.sendForm("service_7ser76b", "template_qiid335", form)
          .then(() => this.$swal(
            "Success",
            "Message sent successfully!",
            "success",
          ))
          .catch(console.error)
          .finally(() => window.$("#contact-modal").modal("hide"));
      });
    },
  };
</script>

<style scoped>
  small {
    display: inline-block;
    font-family: sans-serif!important;
    line-height: 1.45;
    -webkit-font-smoothing: auto;
  }
</style>