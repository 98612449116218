<template>
  <div class="card">
    <img
      :src="info.link"
      class="card-img-top"
      alt="card-img"
    />
    <div class="card-body" v-html="info.text">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  }
}
</script>

<style scoped>
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #93939340;
  border-radius: 30px;
  opacity: 1;
  margin-top: 50px;
  margin-bottom: 120px;
  border: none;
}

.card-img-top {
  border-radius: 30px 30px 0 0 !important;
}

.card-body {
  padding: 40px 100px 40px 41px;
}

.card-body >>> .card-title {
  text-align: left;
  font: normal normal bold 25px/29px Montserrat;
  letter-spacing: 0px;
  color: #1b1a1a;
  opacity: 1;
}

.card-body >>> .card-title a {
  color: #ff0313;
}

@media (max-width: 425px) {
  .card-body >>> .card-title {
    font-size: 14px;
    line-height: 20px;
  }

  .card-body {
  padding: 16px 35px 10px 19px;
}
}
</style>
