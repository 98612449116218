<template>
  <div
   data-aos="fade-right"
    class="card-item-modulo mt-3"
    data-toggle="collapse"
    :data-target="'#collapseModulo' + id"
    aria-expanded="false"
    :aria-controls="'collapseModulo' + id"
  >
    <div
      class="card-item-modulo-header align-items-center d-flex"
      :id="'headingModulo' + id"
    >
      <h2 class="mb-0 p-0  text-left text-modulo" v-if="title">
        {{ title }}
      </h2>
      <img
        src="@/assets/Images/Formation/Icon awesome-chevron-down.png"
        alt="icon-arrow"
      />
    </div>

    <div
      :id="'collapseModulo' + id"
      class="collapse"
      :aria-labelledby="'headingModulo' + id"
      :data-parent="accordion"
    >
      <div class="card-body" v-html="description"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "title", "description", "accordion"],
};
</script>

<style scoped>
.card-item-modulo {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px #0000001a;
  border-radius: 15px;
  opacity: 1;
  padding: 38px 52px 30px 55px;
  cursor: pointer;
  margin-bottom: 40px;
}

.text-modulo {
  text-align: left;
  font: normal normal bold 26px/32px Montserrat;
  letter-spacing: 0px;
  color: #080808;
  opacity: 1;
  padding-left: 1.3rem;
}

.card-body >>> p {
  text-align: left;
  font: normal normal 500 18px/32px Montserrat;
  letter-spacing: 0px;
  color: #080808;
  opacity: 1;
}

.card-item-modulo-header {
  justify-content: space-between;
}

.card-item-modulo-header img {
  height: 12px !important;
  width: 20px !important;
}

@media (max-width: 425px) {
  .card-item-modulo {
    border: 1px solid #d5d5d5;
  }

  .card-body >>> p {
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
