<template>
  <div>
    <ContactModal/>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row" id="first-sec">
        <div class="col-12">
          <div class="row">
            <HomeSection class="padding" v-if="!mobile" id="sec1" :imgLeft="false" :infoSec="locale[lang].FORMATION.INFOSEC1" :haveButton="false" />
            <MobileSec id="mobile1" v-else :infoSec="locale[lang].FORMATION.INFOSEC1" :titleTop="false" :haveButton="false" />
          </div>
          <div class="row">
            <div class="section-card padding">
              <div class="container-fluid">
                <!--  START CARD ONE -->
                <div class="card-one">
                  <h3 class="secondary-title px-sm-5">
                    {{ locale[lang].FORMATION.CARD.TITLE }}
                  </h3>
                  <div class="line mx-auto mt-4"></div>
                  <p class="simple-text mt-4 t-black">
                    {{ locale[lang].FORMATION.CARD.TEXT }}
                  </p>
                </div>
                <!--  END CARD ONE -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="cards">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="cards" id="card1">
            <div class="text-card">
              <p v-html="locale[lang].FORMATION.IMG_CARDS.CARDS.CARD_1"></p>
            </div>
            <img class="img-fluid" src="@/assets/Images/Formation/Enmascarar grupo 101.png" alt="card-img" />
          </div>

          <div class="cards" id="card2">
            <div class="text-card">
              <p v-html="locale[lang].FORMATION.IMG_CARDS.CARDS.CARD_2"></p>
            </div>
            <img class="img-fluid" src="@/assets/Images/Formation/Enmascarar grupo 102.png" alt="card-img" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
          <div class="examples">
            <div id="title-ex">
              <h5 data-aos="fade-right">{{ locale[lang].FORMATION.IMG_CARDS.CONTENT.TITLE }}</h5>
            </div>
            <p>
              {{ locale[lang].FORMATION.IMG_CARDS.CONTENT.TEXT }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="!mobile" class="row padding" id="saison">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="first">
          <img class="img-fluid" src="@/assets/Images/Formation/Grupo 1110.png" alt="grupo 736" />
          <p>
            {{ locale[lang].FORMATION.SAISON.TEXT }}
          </p>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="second">
          <div class="title-img">
            <h1 :class="{ es: lang == 'es' }">{{ locale[lang].FORMATION.SAISON.IMG.TITLE }}</h1>
          </div>
          <div class="subtitles">
            <div id="sub1">
              <h5>{{ locale[lang].FORMATION.SAISON.IMG.SUB_1 }}</h5>
            </div>
            <div id="sub2">
              <h5>{{ locale[lang].FORMATION.SAISON.IMG.SUB_2 }}</h5>
            </div>
          </div>
          <img class="img-fluid" src="@/assets/Images/Formation/Grupo 1008.png" alt="" />
        </div>
      </div>

      <div v-else class="row" id="saisonM">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="firstM">
          <div class="title-img">
            <h1 :class="{ es: lang == 'es' }">{{ locale[lang].FORMATION.SAISON.IMG.TITLE }}</h1>
          </div>
          <div class="subtitles">
            <div id="sub1">
              <h5>{{ locale[lang].FORMATION.SAISON.IMG.SUB_1 }}</h5>
            </div>
            <div id="sub2">
              <h5>{{ locale[lang].FORMATION.SAISON.IMG.SUB_2 }}</h5>
            </div>
          </div>
          <img class="img-fluid" src="@/assets/Images/Formation/Grupo 1008.png" alt="" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="secondM">
          <p>
            {{ locale[lang].FORMATION.SAISON.TEXT }}
          </p>
        </div>
      </div>

      <!-- video -->
      <div class="row" id="video-sec">
        <div class="col-12">
          <div class="embed-responsive embed-responsive-21by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/0TsW3Yvx3FM" frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""></iframe>
          </div>
          <p>
            {{ locale[lang].FORMATION.VIDEO_SUB }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-4" id="public">
          <div id="title-1" data-aos="fade-right" v-if="lang != 'es'">
            <h5>{{ locale[lang].FORMATION.EXEMPLES.TITLE }}</h5>
          </div>
          <div class="row" v-if="!mobile && lang != 'es'">
            <div class="col-6" v-for="(i, key) in locale[lang].FORMATION.EXEMPLES.CARDS" :key="key">
              <FormationCard :info="i" />
            </div>
          </div>
          <div class="row" v-else-if="lang != 'es'">
            <FormationCarousel :infoCards="locale[lang].FORMATION.EXEMPLES.CARDS" />
          </div>
          <div id="title-2" :class="{ 'mt-5' : lang == 'es' }" data-aos="fade-right">
            <h1>{{ locale[lang].FORMATION.EXEMPLES.ATTENTION.TITLE }}</h1>
          </div>
          <div class="attention">
            <p v-for="(text, key) in locale[lang].FORMATION.EXEMPLES.ATTENTION.CONTENT" :key="key">
              {{ text }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <HomeSection v-if="!mobile" id="sec2" :imgLeft="false" :infoSec="locale[lang].FORMATION.INFOSEC2" :haveButton="true" :link="locale[lang].MODAL_LINKS.FORMATION" />

        <MobileSec id="mobile2" v-else :infoSec="locale[lang].FORMATION.INFOSEC2" :titleTop="false" :haveButton="true" :link="locale[lang].MODAL_LINKS.FORMATION" />
      </div>

      <!-- Modulos -->

      <div class="row" id="modulos">
        <div class="col-12">
          <div class="section-modulos">
            <div class="section-modulos-content">
              <div class="container-fluid">
                <div class="tit-modulos">
                  <div class="tertiary-title" data-aos="fade-right">
                    <h5>
                      {{ locale[lang].FORMATION.MODULOS.TITLE }}
                    </h5>
                  </div>
                </div>

                <div id="content-card-modulo" class="content-card-modulo mt-3">
                  <CardModulo :name="item.name" :count="item.count" :active="index == moduleActive"
                    v-for="(item, index) in locale[lang].FORMATION.MODULOS.ITEMS" :key="index" @click.native="moduleActive = index"
                    :class="{ active: index == moduleActive }"></CardModulo>
                </div>
              </div>

              <div class="content-item-modulos position-relative">
                <div class="container-fluid">
                  <div class="accordion" id="accordion1">
                    <ItemModulo :id="item.id" :title="item.title" v-for="(item, index) in locale[lang].FORMATION.MODULOS.ITEMS[moduleActive].items"
                      :key="index" :description="item.description" :accordion="'#accordion1'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <RotateSec id="secRot" :iconInfo="locale[lang].FORMATION.ICONINFO" :mobile="mobile" />
      </div>
      <div class="row">
        <OpinionCarousel id="carousel" :title="carouselTitle" :mobile="mobile" />
      </div>

      <div class="row">
        <div class="col-12" id="confiance">
          <div class="title-c" data-aos="fade-right">
            <h1>{{ locale[lang].FORMATION.CONFIANCE.TITLE }}</h1>
          </div>
          <p v-for="(text, key) in locale[lang].FORMATION.CONFIANCE.CONTENT" :key="key">
            {{text}}
          </p>
        </div>
      </div>

      <!-- Questions  -->

      <div class="row" id="questions">
        <div class="col-12">
          <div class="section-questions">
            <div class="section-questions-content">
              <div class="container-fluid">
                <div class="tit-modulos">
                  <div class="tertiary-title" data-aos="fade-right">
                    <h5>
                      {{ locale[lang].FORMATION.QUESTIONS.TITLE }}
                    </h5>
                  </div>
                </div>
              </div>

              <div class="content-item-modulos position-relative">
                <div class="container-fluid">
                  <div class="accordion" id="accordion2">
                    <ItemModulo :id="item.id" :title="item.question" v-for="(item, index) in locale[lang].FORMATION.QUESTIONS.ITEMS" :key="index"
                      :description="item.description" :accordion="'#accordion2'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <HomeSection v-if="!mobile" id="sec3" :imgLeft="true" :infoSec="locale[lang].FORMATION.INFOSEC3" :haveButton="false" />
        <MobileSec id="mobile3" v-else :infoSec="locale[lang].FORMATION.INFOSEC3" :titleTop="false" :haveButton="false" />
      </div>

      <div class="row" id="end">
        <HomeSection v-if="!mobile" id="sec4" :imgLeft="false" :infoSec="locale[lang].FORMATION.INFOSEC2" :haveButton="true" :link="locale[lang].MODAL_LINKS.FORMATION" />
        <div class="col-12" v-else>
          <div class="row">
            <div class="col-12 title-end" data-aos="fade-right">
              <h1>{{locale[lang].FORMATION.END.TITLE}}</h1>
              <p>
                {{locale[lang].FORMATION.END.TEXT}}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <img class="img-fluid" src="@/assets/Images/Formation/Grupo 973.png" alt="letter-img" />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button class="btn btn-sc" @click="goEmail">{{locale[lang].FORMATION.END.BUTTON}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import HomeSection from "@/components/Home/HomeSection.vue";
import CardModulo from "@/components/CardModulo.vue";
import ItemModulo from "@/components/ItemModulo.vue";
import RotateSec from "@/components/Home/RotateSec.vue";
import OpinionCarousel from "@/components/OpinionCarousel.vue";
import MobileSec from "@/components/MobileSec.vue";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
import FormationCard from "@/components/FormationCard.vue";
import FormationCarousel from "@/components/FormationCarousel.vue";
import ContactModal from "@/components/ContactModal.vue"
export default {
  components: {
    HomeSection,
    CardModulo,
    ItemModulo,
    RotateSec,
    OpinionCarousel,
    MobileSec,
    FormationCard,
    NavBar,
    Footer,
    FormationCarousel,
    ContactModal,
  },
  created() {
    var w = window.innerWidth;
      this.carouselTitle = this.locale[this.lang].FORMATION.OPINION_TITLE;
    if (w <= 1024) {
      this.mobile = true;
      this.carouselTitle = this.locale[this.lang].FORMATION.OPINION_TITLE_MOBILE;
    }
  },
  methods: {
    goEmail() {
      if(this.lang != 'es') {
        window.$("#contact-modal").modal("show");
      } else {
        window.open(this.locale[this.lang].MODAL_LINKS.FORMATION, "_blank")
      }
    },
  },
  data() {
    return {
      mobile: false,
      moduleActive: 0,
    };
  },
};
</script>

<style scoped>
#sec1 >>> h2,
#sec4 >>> h2 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

#sec1 >>> p {
  text-align: left;
  font: normal normal 500 18px/32px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

#sec1 >>> .button-sec,
#sec2 >>> .button-sec,
#sec4 >>> .button-sec {
  text-align: left;
}

#sec1 >>> button {
  font: normal normal bold 22px/27px Montserrat;
  padding: 24px 45px;
}

#sec1 >>> .text {
  padding-top: 160px;
}

.padding {
  padding: 100px 192px;
}

.card-one {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 30px;
  opacity: 1;
  padding: 86px 50px;
  position: relative;
}

.secondary-title {
  text-align: center;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-bottom: 100px;
}

.line::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 48%;
  bottom: 42%;
  opacity: 1;
}

.simple-text,
#confiance p {
  text-align: center;
  font: normal normal 500 21px/32px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

#confiance p {
  text-align: left;
  color: #ffffff;
}

.cards {
  position: relative;
  margin-bottom: 42px;
}

.text-card p {
  text-align: left;
  font: normal normal bold 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  position: absolute;
  top: 30%;
  left: 7%;
}

.examples {
  position: relative;
  margin-left: 12%;
  margin-top: 30px;
}

#sec2 >>> .title-sec {
  position: relative;
}

.examples p,
#sec2 >>> .text-sec p,
#sec4 >>> .text-sec p {
  text-align: left;
  font: normal normal 500 21px/32px Montserrat;
  letter-spacing: 0px;
  color: #0f0f0f;
  opacity: 1;
}

.examples #title-ex {
  margin-bottom: 62px;
}

.examples #title-ex::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 0%;
  top: 32%;
  opacity: 1;
}

.examples #title-ex h5,
#public h1 {
  text-align: left;
  font: normal normal bold 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

#saison {
  padding: 50px 192px 0 192px;
}

#saison p {
  text-align: center;
  font: normal normal 500 21px/32px Montserrat;
  letter-spacing: 0px;
  color: #080808;
  opacity: 1;
  padding-top: 60px;
  padding-left: 14%;
}

#first img {
  margin-right: 12%;
}

#second {
  position: relative;
  margin-top: 50px;
}

.title-img,
#sub1,
#sub2 {
  position: absolute;
}

.title-img {
  top: 5%;
  left: 16%;
}

.subtitles {
  display: inline-flex;
}

#sub1 {
  left: 15%;
  top: 53%;
}

#sub2 {
  left: 54%;
}

.title-img h1,
#sec2 >>> .title-sec h2,
#sec3 >>> .title-sec h2,
#confiance h1 {
  text-align: left;
  font: normal normal bold 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.title-img h1.es {
  font-size: 32px;
}

#sec2 >>> .text-sec p {
  color: #ffffff;
}

#sec2 >>> .btn {
  color: #ffffff;
  border-color: #ffffff !important;
}

#sec2 >>> .btn,
#sec4 >>> .btn {
  padding: 24px 150px;
}

#sec3 >>> .text-sec p {
  line-height: 42px !important;
  color: #ffffff !important;
}

#sec3 >>> img {
  height: auto !important;
}

.subtitles h5 {
  text-align: left;
  font: normal normal bold 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #090909;
  opacity: 1;
  margin-right: 95px;
}

#public {
  padding: 0 185px;
}

#title-1,
#title-2 {
  position: relative;
  margin-bottom: 75px;
}

#public h5,
#public p,
#sec3 >>> .text-sec p {
  text-align: left;
  font: normal normal 600 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

#sec3 >>> .text {
  padding-right: 5% !important;
}

#public .attention p {
  text-align: center;
  line-height: 32px;
  font-weight: 500;
}

#sec3 >>> .title-sec::after,
#title-1::after,
#title-2::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  top: 80px;
  left: 0;
  opacity: 1;
}

#sec3 >>> .title-sec::after {
  top: 49%;
  left: 5%;
}

#title-2::after {
  top: 65px !important;
  left: 48% !important;
}

#title-2 h1 {
  text-align: center !important;
  line-height: 32px;
}

#sec4 {
  padding: 0 140px 100px 140px;
}

#sec2 >>> button,
#sec4 >>> button {
  padding: 23px 68px;
}

#sec2 >>> .title-sec::after,
#sec4 >>> .title-sec::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 2px;
  bottom: -20px;
  opacity: 1;
}

.section-modulos,
.section-questions {
  padding: 0 156px;
}

.tit-modulos {
  position: relative;
  margin-bottom: 100px;
}

.title-c {
  position: relative;
  margin-bottom: 100px;
}

.tertiary-title::after,
.title-c::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 0;
  bottom: -40px;
  opacity: 1;
}

.tertiary-title h5 {
  text-align: left;
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  color: #21282e;
  opacity: 1;
}

.content-card-modulo {
  display: flex;
}

#secRot >>> .container-fluid {
  padding: 0;
}

#secRot >>> .rotate {
  height: 219px;
  width: 219px;
  margin-left: 20%;
}

#secRot >>> .text {
  margin-left: 0;
}

#secRot >>> .text p {
  font-size: 18px;
}

#secRot >>> #rot-0 img {
  top: 12%;
  left: 38%;
}

#secRot >>> #rot-1 img {
  top: 12%;
  left: 39.5%;
}

#secRot >>> #rot-2 img {
  top: 13%;
  left: 35%;
}

#secRot >>> #rot-3 img {
  top: 12%;
  left: 40%;
}

.embed-responsive {
  height: 80%;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe {
  border-radius: 30px;
}

#video-sec p {
  text-align: left;
  font: normal normal 500 20px/24px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 27px;
}

#sec4 >>> .title-sec {
  position: relative;
}

/* Background images */

#first-sec {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/soccer-10-web.appspot.com/o/Enmascarar%20grupo%20197.svg?alt=media&token=3b08790a-c238-4643-b250-dbfd738e5bad");
  background-repeat: no-repeat;
  background-position: 0 75%;
}

#cards {
  background-image: url("../assets/Images/Formation/Grupo 1007.png"),
    url("../assets/Images/Formation/Grupo 685.png"),
    url("../assets/Images/Formation/Enmascarar grupo 130.png"),
    url("../assets/Images/Formation/Elipse 105.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: 6% 6%, 87% 85%, 100% -45px, 47% 80%;
  padding-left: 12%;
  padding-right: 10%;
  padding: 15% 10% 10% 12%;
}

#sec2 {
  background-image: url("../assets/Images/Formation/Grupo 806.png"),
    url("../assets/Images/Formation/Enmascarar grupo 133.png");
  background-repeat: no-repeat, no-repeat;
  background-position: 100% 32%, 0 0;
  padding: 323px 140px;
}

#sec3 {
  background-image: url("../assets/Images/Formation/Enmascarar grupo 136.png");
  background-repeat: no-repeat;
  background-position: 100% 32%;
  padding: 10% 0 15% 0;
}

#secRot {
  background-image: url("../assets/Images/Formation/Enmascarar grupo 196.png");
  background-repeat: no-repeat;
  background-position: 100% 32%;
  padding: 323px 12% 20% 8%;
}

#modulos {
  background-image: url("../assets/Images/Formation/Enmascarar grupo 198.png"),
    url("../assets/Images/Saison/Grupo 691.png");
  background-repeat: no-repeat;
  background-position: 0 80%, 92% 100%;
}

#questions {
  background-image: url("../assets/Images/Formation/Enmascarar grupo 135.png"),
    url("../assets/Images/Formation/Grupo 1003.png");
  background-repeat: no-repeat;
  background-position: 100% 0, 6% 100%;
}

#carousel {
  background-image: url("../assets/Images/Formation/Grupo 1109.png"),
    url("../assets/Images/Saison/Grafismo1.png");
  background-repeat: no-repeat;
  background-position: 54% 100%, 0 50%;
  padding: 0 100px 15% 100px !important;
}

#confiance {
  background-image: url("../assets/Images/Formation/Trazado 2742.png"),
    url("../assets/Images/Formation/Enmascarar grupo 134.png");
  background-repeat: no-repeat;
  background-position: 100% 100%, 100% 32%;
  padding: 323px 16% 323px 140px;
}

#video-sec {
  background-image: url("../assets/Images/Formation/Trazado 1897.png"),
    url("../assets/Images/Formation/Enmascarar grupo 132.png");
  background-repeat: no-repeat;
  background-position: 90% 95%, 100% 32%;
  background-size: auto, cover;
  padding: 14% 10% 12% 10%;
}

@media (max-width: 1750px) {
  .title-img h1 {
    font-size: 30px;
  }

  .title-img h1.es {
    font-size: 24px;
  }

  .subtitles h5 {
    font-size: 20px;
  }
}

@media (max-width: 1700px) {
  .padding {
    padding: 100px;
  }

  #cards {
    padding: 15% 8% 10% 10%;
  }

  .text-card p {
    top: 26%;
    left: 4%;
  }

  #saison {
    padding: 50px 85px 0 85px;
  }

  .title-img {
    top: 6%;
    left: 13%;
  }

  .title-img h1 {
    font-size: 32px;
    line-height: 40px;
  }

  .title-img h1.es {
    font-size: 26px;
  }

  #saison p {
    padding-left: 0;
  }

  .subtitles h5 {
    margin-right: 0;
  }

  #sub1 {
    left: 10%;
    top: 52%;
  }

  #sub2 {
    left: 52%;
  }

  #carousel {
    background-size: auto, 40%;
  }

  #sec3 {
    background-position: 0 0;
    padding: 15% 0 21% 0;
  }

  #sec3 >>> .title-sec::after {
    top: 42%;
    left: 7%;
  }

  #sec2 >>> .title-sec::after {
    left: 8%;
    top: 40%;
  }

  #secRot >>> .rotate {
    margin-left: 4%;
  }

  #secRot >>> #rot-0 img {
    left: 31%;
  }

  #secRot >>> #rot-1 img {
    left: 34.5%;
  }

  #secRot >>> #rot-2 img {
    left: 27%;
  }

  #secRot >>> #rot-3 img {
    left: 35%;
  }
}

@media (max-width: 1280px) {
  .title-img {
    left: 11%;
  }

  #sub1 {
    top: 50%;
  }

  #sub2 {
    left: 51%;
  }
}

@media (max-width: 1024px) {
  #first-sec {
    background-image: none;
  }

  #mobile1 >>> .text-sec p {
    font-size: 16px;
  }

  #first-sec .card-one {
    background-color: transparent;
    box-shadow: none;
    padding: 0 3%;
  }

  #first-sec .section-card {
    padding: 15% 0;
  }

  #first-sec .secondary-title {
    font: normal normal bold 18px/20px Montserrat;
    letter-spacing: 0px;
    color: #080808;
    margin-bottom: 50px;
  }

  #first-sec .line::after {
    bottom: 54%;
    left: 45%;
  }

  #first-sec .simple-text {
    font: normal normal 500 16px/20px Montserrat;
    letter-spacing: 0px;
    color: #080808;
  }

  #cards {
    background-image: none;
    padding: 0;
  }

  #cards .cards {
    margin-bottom: 20px;
  }

  #cards .text-card p {
    font-size: 20px;
    line-height: 25px;
  }

  #cards #card1 .text-card p {
    top: 30%;
  }

  #cards #card2 .text-card p {
    top: 34%;
  }

  #cards .examples {
    margin-left: 3%;
  }

  #cards .examples #title-ex {
    margin-bottom: 20px;
  }

  #cards .examples #title-ex::after {
    border-bottom: none;
  }

  #cards .examples #title-ex h5 {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }

  #cards .examples p,
  #video-sec p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  #saisonM {
    padding: 10% 0;
  }

  #saisonM #firstM {
    padding: 0;
  }

  #saisonM #firstM h1 {
    font-size: 32px;
    line-height: 27px;
  }

  #saisonM #firstM h1.es {
    font-size: 18px;
  }

  .title-img {
    left: 15.5%;
  }

  .subtitles h5 {
    font-size: 22px;
    line-height: 28px;
  }

  #sub1 {
    left: 12%;
    top: 53%;
  }

  #sub2 {
    left: 56%;
    top: 50%;
  }

  #saisonM p {
    font: normal normal 500 16px/20px Montserrat;
    letter-spacing: 0px;
    color: #0f0f0f;
  }

  #video-sec {
    background-image: url("../assets/Images/Formation/Enmascarar grupo 202.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding: 20% 0 25% 0;
  }

  #video-sec video {
    width: 100%;
  }

  #video-sec p {
    margin-left: 0;
  }

  #public {
    margin-top: 45px;
    padding: 0 5%;
  }

  #public h1 {
    text-align: center;
    font-size: 18px;
    line-height: 23px;
  }

  #public h5 {
    text-align: center;
    font-size: 16px;
    line-height: 15px;
  }

  #title-1 {
    margin-bottom: 30px;
  }

  #title-1::after {
    top: 35px;
    left: 45%;
  }

  #title-2 {
    margin-bottom: 35px;
  }

  #title-2::after {
    border-bottom: none;
  }

  #public #title-2 h1,
  #public .attention p {
    font: normal normal 500 20px/20px Montserrat;
    letter-spacing: 0px;
    color: #0f0f0f;
  }

  #public .attention p {
    font-size: 16px !important;
  }

  #mobile2 {
    background-image: url("../assets/Images/Formation/Enmascarar grupo 203.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding: 30% 2%;
  }

  #mobile2 >>> img {
    display: none;
  }

  #mobile2 >>> h2 {
    font: normal normal bold 20px/20px Montserrat;
    color: #ffffff;
    margin-bottom: 30px;
  }

  #mobile2 >>> p {
    font: normal normal 500 16px/20px Montserrat;
    color: #ffffff;
    margin-bottom: 60px;
  }

  #mobile2 >>> .btn-sc {
    color: #ffffff;
    border-color: #ffffff !important;
    padding: 30px 100px;
  }

  #modulos {
    margin-top: 30px;
  }

  #modulos .tit-modulos {
    margin-bottom: 42px;
  }

  #modulos .tertiary-title::after {
    width: 32px;
    left: 0%;
    bottom: -10px;
  }

  #modulos .tit-modulos h5 {
    text-align: left;
    font: normal normal 800 18px/47px Montserrat;
    color: #21282e;
  }

  #modulos >>> .card-modulo {
    min-width: 104px !important;
    height: 124px !important;
    padding: 0;
    margin-right: 12px;
  }

  #modulos >>> .card-modulo img {
    display: none;
  }

  #modulos >>> .card-modulo h5 {
    text-align: left;
    font: normal normal 600 17px/20px Montserrat;
  }

  #modulos >>> .card-modulo-title {
    margin-left: 15px;
  }

  #modulos >>> .card-modulo-footer {
    position: relative;
    margin: 25% 15% 0 0;
  }

  #secRot {
    background-image: url("../assets/Images/Formation/Enmascarar grupo 204.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding: 18% 2%;
  }

  #secRot >>> .rotate {
    height: 110px;
    width: 110px;
    margin-left: 31%;
  }

  #secRot >>> .text h3 {
    font: normal normal 600 13px/16px Montserrat;
  }

  #secRot >>> .text p {
    font: normal normal 500 8px/9px Montserrat;
  }

  #secRot >>> #rot-0 img {
    top: 12%;
    left: 41.5%;
    height: 58px;
  }

  #secRot >>> #rot-1 img {
    top: 12%;
    left: 43%;
    height: 63px;
  }

  #secRot >>> #rot-2 img {
    top: 12%;
    left: 40%;
    height: 63px;
  }

  #secRot >>> #rot-3 img {
    top: 11%;
    left: 43%;
    height: 64px;
  }

  #carousel {
    background-image: none;
    padding: 0 15% !important;
  }

  #carousel >>> .title {
    padding: 0 10%;
    margin-top: 100px;
    margin-bottom: 30px;
    position: relative;
  }

  #carousel >>> .title::after {
    content: "";
    position: absolute;
    border-bottom: 3px solid #ff0313;
    width: 30px;
    left: 47%;
    bottom: -30%;
    opacity: 1;
  }

  #carousel >>> .title h3 {
    text-align: center;
    font-size: 21px;
    line-height: 25px;
  }

  #carousel >>> .opinion-card {
    padding: 15px 16px;
  }

  #carousel >>> .carousel-indicators {
    left: 0;
    bottom: -30px;
  }

  #cardsM >>> .carousel-indicators li,
  #carousel >>> .carousel-indicators li,
  #notiCarousel >>> .carousel-indicators li {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    opacity: 1;
  }

  #carousel >>> .left-img {
    display: flex;
  }

  #carousel >>> .img-cm {
    width: 46px;
    height: 46px;
    margin-right: 14px;
  }

  #carousel >>> p {
    text-align: left;
    font: italic normal 500 12px/21px Montserrat;
    letter-spacing: 0px;
    color: #444444;
  }

  #carousel >>> .body h3 {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 0;
  }

  #carousel >>> .body h5 {
    font-size: 12px;
    line-height: 22px;
  }

  #confiance {
    background-image: url("../assets/Images/Formation/Enmascarar grupo 205.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    padding: 30% 5%;
    background-size: 100% 95%;
  }

  #confiance h1 {
    text-align: center;
    font: normal normal bold 20px/20px Montserrat;
  }

  #confiance .title-c {
    margin-bottom: 25px;
  }

  #confiance .title-c::after {
    border-bottom: none;
  }

  #confiance p {
    text-align: center;
    font: normal normal 500 12px/20px Montserrat;
  }

  .section-modulos,
  .section-questions,
  #questions > .col-12 {
    padding: 0;
  }

  .section-modulos >>> .card-item-modulo {
    padding: 30px 32px 30px 24px;
    margin-bottom: 20px;
  }

  .section-modulos >>> .text-modulo {
    font-size: 18px;
    line-height: 18px;
  }

  .section-questions >>> .card-item-modulo {
    padding: 20px 20px 22px 15px;
    margin-bottom: 11px;
  }

  .section-questions >>> .text-modulo {
    font: normal normal 600 16px/18px Montserrat;
  }

  #questions .tertiary-title::after {
    width: 32px;
    left: 44%;
    bottom: -24px;
  }

  #modulos,
  #questions {
    background-image: none;
  }

  #questions {
    margin-bottom: 45px;
  }

  #questions .tit-modulos {
    margin-bottom: 70px;
  }

  #questions .tit-modulos h5 {
    text-align: center;
    font: normal normal 800 20px/47px Montserrat;
    color: #21282e;
  }

  #mobile3 {
    background-image: url("../assets/Images/Formation/Enmascarar grupo 205.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    padding: 25% 2%;
  }

  #mobile3 >>> img {
    width: 194px;
  }

  #mobile3 >>> h2 {
    color: #ffffff;
    margin-bottom: 25px;
  }

  #mobile3 >>> .text-sec p {
    font: normal normal 500 12px/20px Montserrat;
    color: #ffffff;
  }

  #end {
    padding: 20% 3%;
  }

  .title-end h1 {
    text-align: center;
    font: normal normal bold 20px/20px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
    margin-bottom: 20px;
  }

  .title-end p {
    text-align: center;
    font: normal normal 500 12px/20px Montserrat;
    letter-spacing: 0px;
    color: #0f1010;
  }

  #end .btn-sc {
    background-image: url("../assets/Images/Background/ball.png");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    /* border: 1px solid #0f1010 !important; */
    box-shadow: 0px 3px 30px #00000029;
    border-radius: 15px;
    font: normal normal bold 12px/15px Montserrat;
    color: #0f1010;
    text-transform: uppercase;
    padding: 14px 113px 16px 94px;
    margin-top: 35px;
    height: 90px;
    background-size: 95px 80px;
  }
}

@media (max-width: 425px) {
  #cards .text-card p {
    font-size: 14px;
    line-height: 14px;
  }

  #first-sec .line::after {
    bottom: 60%;
    left: 42%;
  }

  #saisonM #firstM h1 {
    font-size: 22px;
  }

  #saisonM #firstM h1.es {
    font-size: 18px;
  }

  .subtitles h5 {
    font-size: 14px;
    line-height: 18px;
  }

  #title-1::after {
    top: 60px;
    left: 42%;
  }

  #carousel {
    padding: 0 0 15% !important;
  }

  #modulos >>> .card-modulo-footer {
    margin: 45% 30% 0 0;
  }

  #secRot >>> .rotate {
    margin-left: 18%;
  }

  #secRot >>> #rot-0 img {
    top: 12%;
    left: 35.5%;
    height: 58px;
  }

  #secRot >>> #rot-1 img {
    top: 12%;
    left: 37%;
    height: 63px;
  }

  #secRot >>> #rot-2 img {
    top: 12%;
    left: 33%;
    height: 63px;
  }

  #secRot >>> #rot-3 img {
    top: 11%;
    left: 37%;
    height: 64px;
  }

  #secRot >>> .text h3 {
    font: normal normal 600 13px/16px Montserrat;
  }

  #secRot >>> .text p {
    font: normal normal 500 8px/9px Montserrat;
  }
}

@media (max-width: 375px) {

  .title-img {
    left: 11%;
  }

  #cards .text-card p {
    font-size: 12px;
  }

  #title-1::after {
    left: 40%;
  }

  #sub1 {
    top: 52.5%;
  }

  #sub2 {
    left: 54%;
  }

  #first-sec .line::after {
    bottom: 62%;
    left: 40%;
  }

  #mobile2 {
    padding: 34% 2%;
  }

  #modulos >>> .card-modulo {
    width: 104px;
  }

  #modulos > .col-12 {
    padding: 0;
  }

  #secRot >>> #rot-0 img {
    top: 12%;
    left: 37.5%;
  }

  #secRot >>> #rot-1 img {
    top: 12%;
    left: 39.5%;
  }

  #secRot >>> #rot-2 img {
    top: 12%;
    left: 34.5%;
  }

  #secRot >>> #rot-3 img {
    top: 12%;
    left: 40%;
  }
}
</style>
